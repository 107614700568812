<template>
  <div>
    <!--    &lt;!&ndash; search input &ndash;&gt;-->
    <!--    <section id="app-market-search">-->
    <!--      <b-card-->
    <!--        no-body-->
    <!--        class="app-market-bg text-center"-->
    <!--        :style="{backgroundImage: `url(${require('@/assets/images/banner/banner.png')})`}"-->
    <!--      >-->
    <!--        <b-card-body class="card-body">-->
    <!--          <h2 class="text-primary">-->
    <!--            {{ $t('modules.apps._list.search_title') }}-->
    <!--          </h2>-->
    <!--          <b-card-text class="mb-2">-->
    <!--            <span>{{ $t('modules.apps._list.search_subtitle') }}</span>-->
    <!--          </b-card-text>-->
    <!--          &lt;!&ndash;          <b-card-text class="mb-2">&ndash;&gt;-->
    <!--          &lt;!&ndash;            <span>Popular searches: </span>&ndash;&gt;-->
    <!--          &lt;!&ndash;            <span class="font-weight-bolder">Sales automation, Email marketing</span>&ndash;&gt;-->
    <!--          &lt;!&ndash;          </b-card-text>&ndash;&gt;-->

    <!--          &lt;!&ndash; form &ndash;&gt;-->
    <!--          <b-form class="kb-search-input">-->
    <!--            <b-input-group class="input-group-merge">-->
    <!--              <b-input-group-prepend is-text>-->
    <!--                <feather-icon icon="SearchIcon" />-->
    <!--              </b-input-group-prepend>-->
    <!--              <b-form-input-->
    <!--                id="searchbar"-->
    <!--                v-model="appsMarketSearchQuery"-->
    <!--                :placeholder="$t('modules.apps._list.search_placeholder')"-->
    <!--              />-->
    <!--            </b-input-group>-->
    <!--          </b-form>-->
    <!--          &lt;!&ndash; form &ndash;&gt;-->
    <!--        </b-card-body>-->
    <!--      </b-card>-->
    <!--    </section>-->
    <!--    &lt;!&ndash;/ search input &ndash;&gt;-->

    <section id="app-market-content">

      <!-- content -->
      <b-row class="kb-search-content-info match-height">
        <b-col
          v-for="item in filteredItems"
          :key="item.id"
          :md="cols"
          sm="6"
          class="kb-search-content"
        >
          <b-card
            class="text-center cursor-pointer"
            :img-src="categoriesImages[item.slug]"
            :img-alt="item.slug"
            img-top
            @click="$router.push({ name: 'apps-category', params: { category: item.slug, categoryName: item.name } })"
          >
            <h4>{{ item.name }}</h4>
            <b-card-text class="mt-1">
              {{ item.description }}
            </b-card-text>
          </b-card>
        </b-col>
        <b-col
          v-if="filteredItems.length"
          key="more-items-soon"
          :md="cols"
          sm="6"
          class="kb-search-content"
        >
          <b-card
            class="text-center cursor-default"
            img-top
            border-variant="secondary"
            bg-variant="transparent"
          >
            <h4>{{ $t('modules.apps._list.soon_category_title') }}</h4>
            <b-card-text class="mt-1">
              {{ $t('modules.apps._list.soon_category_subtitle') }}
            </b-card-text>
          </b-card>
        </b-col>
        <b-col
          v-show="!filteredItems.length"
          cols="12"
          class="text-center"
        >
          <h4 class="mt-4">
            {{ $t('modules.apps._messages.no_categories_message') }}
          </h4>
        </b-col>
      </b-row>
      <apps-announcements />
    </section>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText, BAlert,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted, ref } from 'vue'
import appCategoryStoreModule from '@/views/models/apps/appCategoryStoreModule'
import {catchException, shop, toast} from '@core/utils/utils'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import AppsAnnouncements from "@/views/models/apps/AppsAnnouncements.vue";

const categoriesImages = {
  'store-product': require('@/assets/images/illustration/products.png'),
  marketing: require('@/assets/images/illustration/marketing.png'),
  'campaigns-tracking': require('@/assets/images/illustration/marketing.svg'),
  'customer-service': require('@/assets/images/illustration/sales.svg'),
  'shipping-and-delivery': require('@/assets/images/illustration/email.svg'),
  'accounting-invoicing': require('@/assets/images/illustration/demand.svg'),
  api: require('@/assets/images/illustration/api.svg'),
  other: require('@/assets/images/illustration/personalization.svg'),
}

export default {
  methods: {shop},
  components: {
    AppsAnnouncements,
    BAlert,
    BRow,
    BCol,
    BCard,
    BCardText,
  },
  data() {
    return {
      appsMarketSearchQuery: '',
    }
  },
  computed: {
    filteredItems() {
      const appsMarketSearchQueryLower = this.appsMarketSearchQuery.toLowerCase()
      return this.categories.filter(item => item.name.toLowerCase().includes(appsMarketSearchQueryLower) || item.description.toLowerCase().includes(appsMarketSearchQueryLower))
    },
    cols() {
      if (this.categories.length > 3) {
        return 4
      }
      return 12 / (this.categories.length + 1)
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'app_categories'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, appCategoryStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const categories = ref([])
    const isLoading = ref(false)
    // Use toast
    const { t } = useI18nUtils()

    store
      .dispatch(`${STORE_MODULE_NAME}/fetchAll`)
      .then(response => {
        const responseData = response.data
        categories.value = responseData.data
      })
      .catch(e => {
        catchException(e)
        toast(
          'danger',
          t('message.error_fetching_list'),
          null,
          'AlertTriangleIcon',
        )
      })
      .finally(() => {
        isLoading.value = false
      })

    return {
      categories,
      isLoading,
      categoriesImages,
    }
  },
}
</script>
<!-- Add join-us link: https://docs.google.com/forms/d/e/1FAIpQLScrMBcfz3s5lgcHCU-S5yVghFuhfss9VrHz8vjp3alBWAAUFA/viewform?usp=sf_link -->
<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
</style>
